const store = [
  {
    path: '/store/index',
    name: 'store',
    meta: {
      title: '商铺信息'
    },
    component: () => import('../views/store/index')
  }
];

export default store;
