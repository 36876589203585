export default [
  {
    path: '/repair/apply',
    name: 'repairapply',
    meta: {
        title: '报修申请'
    },
    component: () => import('../views/repair/repairapply.vue')
  },
  {
    path: '/repair/record',
    name: 'repairrecord',
    meta: {
        title: '报修记录'
    },
    component: () => import('../views/repair/repairerecord.vue')
  },
  {
    path: '/repair/detail',
    name: 'repairdetail',
    meta: {
        title: '报修详情'
    },
    component: () => import('../views/repair/detail.vue')
  }
];
