const contract = [
  {
    path: '/contract/detail',
    name: 'detail',
    meta: {
      title: '合同信息'
    },
    component: () => import('../views/contract/detail')
  },
  {
    path: '/contract/renewal',
    name: 'renewal',
    meta: {
      title: '续签合同'
    },
    component: () => import('../views/contract/renewal')
  },
  {
    path: '/contract/contractSign',
    name: 'contractSign',
    meta: {
      title: '线上合同续签'
    },
    component: () => import('../views/contract/contractSign')
  },
  {
    path: '/contract/offlineAddress',
    name: 'offlineAddress',
    meta: {
      title: '线下续签合同'
    },
    component: () => import('../views/contract/offlineAddress')
  }
];

export default contract;
