<template>
 <div class="empty-item">
 <div  class="empty">
      <div class="imgBox">
        <img src="../../assets/image/empty.png" alt />
        <div>还没有任何数据哦~</div>
      </div>
    </div>
 </div>
</template>
<script></script>
<style lang="scss" scoped>
.empty-item {
  width: 100%;
  height: 100vh;
  position: relative;
  .imgBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 321px;
      object-fit: contain;
    //   height: 258px;
    }
    div {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #529100;
      text-align: center;
    }
  }
}
</style>
