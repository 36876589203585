import { Toast } from 'vant';
import router from './router/index';
import * as requie from './plugin/request';

const urlencode = (str) => {
  str = `${str}`.toString();
  return encodeURIComponent(str)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A')
    .replace(/%20/g, '+');
};

const getQueryString = (name) => {
  let reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  let r = window.location.search.substr(1).match(reg); //search,查询？后面的参数，并匹配正则
  if (r != null) return unescape(r[2]);
  return null;
};

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || '物业管理';
  let propertyWxToken = window.localStorage.getItem('propertyWxToken');
  let propertyToken = window.localStorage.getItem('propertyToken');
  if (propertyWxToken) {
    if (to.name == 'login' && propertyToken) {
      next({
        path: '/home'
      });
    } else {
      next();
    }
  } else {
    if (to.name == 'downloadPDF') {
      next();
      return false;
    }
    let code = getQueryString('code');
    if (code) {
      await requie
        .HttpPost(
          '/app/login',
          {
            code
          },
          {
            isTokn: true,
            isURl: true
          }
        )
        .then((res) => {
          if (res.code == 0) {
            window.localStorage.setItem('propertyWxToken', res.data.token);
            let url = `${window.location.origin}/property${window.location.hash}`;
            // let url = window.location.origin + '/' + window.location.hash;
            window.history.pushState({}, 0, url);
          }
        });
      let res = null;
      await requie.HttpPost('/app/getWechatConfig', {
        url: window.location.href.split('#')[0]
      }, {
        isTokn: true,
        isURl: true
      }).then((data) => {
        if (data.code == 0) {
          res = data;
        }
      });
      next();
      // await wx.config({
      //   debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //   appId: res.data.appId, // 必填，公众号的唯一标识
      //   timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      //   nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      //   signature: res.data.signature,// 必填，签名
      //   jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
      // });
      // await wx.ready(() => {
      //   // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，
      //   // 则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      //   wx.getLocation({
      //     type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      //     success: (res) => {
      //       window.localStorage.setItem('latitude',res.latitude);
      //       window.localStorage.setItem('longitude',res.longitude);
      //       console.log(1);
      //       next();
      //       // requie.HttpPost('/user/lbs',{latitude : res.latitude,longitude : res.longitude}, { isTokn: true, isURl: true }).then(res => {
      //       //   window.localStorage.setItem('geographyMsg',JSON.stringify(res.data));
      //       //
      //       // })
      //     },
      //     cancel : () => {
      //       Toast('你已拒绝授权定位');
      //       next();
      //     },
      //     fail : function(res) {
      //       Toast('定位授权失败，请开启手机定位');
      //       next();
      //     }
      //   });
      // });
      // await wx.error((res) => {
      //   next();
      // })
    } else {
      let appid = process.env.VUE_APP_WECHAT_ID;
      // let appid = 'wx5d031efac9a2a731';
      let redirectUri = urlencode(
        window.location.origin +
        window.location.pathname +
        window.location.search +
        window.location.hash
      );
      let href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      window.location.href = href;
    }
  }
});
