const personal = [
  {
    path: '/personal/index',
    name: 'personal',
    meta: {
      title: '个人信息'
    },
    component: () => import('../views/personal/index')
  },
  {
    path: '/personal/place',
    name: 'place',
    meta: {
      title: '户籍所在地'
    },
    component: () => import('../views/personal/place')
  },
  {
    path: '/personal/address',
    name: 'addressList',
    meta: {
      title: '常驻地'
    },
    component: () => import('../views/personal/address')
  }
];

export default personal;
