const complaint = [
  {
    path: '/complaint/index',
    name: 'complaint',
    meta: {
      title: '投诉建议'
    },
    component: () => import('../views/complaint/index')
  }
];

export default complaint;
