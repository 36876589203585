const waterElectricity = [
  {
    path: '/waterElectricity',
    name: 'waterElectricity',
    meta: {
      title: '智能水电表余额'
    },
    component: () => import('../views/waterElectricity')
  }
];

export default waterElectricity;