const about = [
  {
    path: '/about/index',
    name: 'about',
    meta: {
      title: '关于我们'
    },
    component: () => import('../views/about/index')
  }
];

export default about;
