export default (request) => {
  let exp = window.localStorage.getItem('exp');
  let date = new Date().getTime();
  if (parseFloat(exp) - (300 * 1000) < date) {
    request.HttpPost('/refresh').then(res => {
      if (res.data) {
        window.localStorage.setItem('exp', res.data.claims.exp * 1000);
        window.localStorage.setItem('token', res.data.token);
      }
    });
  }
};
