import {
  createRouter,
  createWebHashHistory
} from 'vue-router';
import contribute from './contribute';
import complaint from './complaint';
import notice from './notice';
import contract from './contract';
import property from './property';
import personal from './personal';
import store from './store';
import account from './account';
import about from './about';
import repair from './repair';
import features from './features';
import waterElectricity from './waterElectricity';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('../views/login')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: () => import('../views/home')
  },
  {
    path: '/my',
    name: 'my',
    meta: {
      title: '个人中心'
    },
    component: () => import('../views/my')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    meta: {
      title: '忘记密码'
    },
    component: () => import('../views/forgetPassword')
  },
  ...contribute,
  ...complaint,
  ...notice,
  ...contract,
  ...property,
  ...personal,
  ...store,
  ...account,
  ...about,
  ...repair,
  ...features,
  ...waterElectricity
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
