import axios from 'axios';
import { Toast } from 'vant';

const RouteUrl = ['/refresh', '/property_daily/merchant/meter/list', '/property_daily/merchant/dh_daily/get', '/property_daily/merchant/pay/list', '/property_daily/merchant/pay/add', '/property_daily/merchant/dh_daily/info'];
const VerifyTokenUrl = [
  '/purchaseLogin/login',
  '/purchaseLogin/selectUser',
  '/shop/updatePassword',
  '/purchaseLogin/sendCode',
  '/purchaseLogin/findPwd',
  '/app/login',
  '/app/getWechatConfig',
  '/user/lbs',
  '/shop/getMarket',
  '/shop/info',
  '/shop/setMarket'
];

const urlencode = (str) => {
  str = `${str}`.toString();
  return encodeURIComponent(str)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A')
    .replace(/%20/g, '+');
};

const authorization = () => {
  // let appid = 'wx5d031efac9a2a731';

  let appid = process.env.VUE_APP_WECHAT_ID;

  let url = `${window.location.origin + window.location.pathname + window.location.search}#/login`;
  // var redirect_uri = urlencode(window.location.origin + window.location.pathname +  window.location.search + window.location.hash);
  let redirect_uri = urlencode(url);
  let href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
  location.href = href;
};

const request = async (data = {}, type) => {
  const result = await axios(data)
    .then((res) => {
      if (res.status >= 400) {
        Toast('请求错误');
        throw new Error('请求错误');
      } else {
        if (res.data.code == 0) {
          return res.data;
        }
        if (res.data.code == -1) {
          Toast(res.data.msg || '未知错误');
          throw new Error(res.data.msg || '未知错误');
        }
        if (res.data.code == 400) {
          Toast('登录失效，请重新登录');
          window.localStorage.setItem('propertyToken', '');
          window.location.href = '/property/#/login';
          throw new Error('登录失效');
        } else if (res.data.code == 403) {
          Toast('微信授权过期，重新授权');
          window.localStorage.setItem('propertyToken', '');
          window.localStorage.setItem('propertyWxToken', '');
          authorization();
          throw new Error('登录失效，请重新登录');
        } else {
          if (res.data.code == 7 && res.data.msg == '无效token') {
            Toast(res.data.msg);
            throw new Error(res.data.msg);
          }
          Toast(res.data.msg);
          throw new Error(res.data.msg);
        }
      }
    })
    .catch((error) => {
      if (error.message == 'Request failed with status code 500') {
        Toast('网络请求失败');
      }
      if (error.message == 'Network Error') {
        Toast('网络错误');
      }
      throw new Error(error);
    });
  return result;
};

export const HttpGet = async (path = '', data = {}, type = {}) => {
  let token = window.localStorage.getItem('propertyToken');
  let propertyWxToken = window.localStorage.getItem('propertyWxToken');
  let userInfo = window.localStorage.getItem('propertyUserInfo')
    ? JSON.parse(window.localStorage.getItem('propertyUserInfo'))
    : {};
  let headers = {
    'Content-Type': 'application/json',
    loginType: 4
  };
  if (VerifyTokenUrl.indexOf(path) != -1) {
    headers.Authorization = `Bearer ${type.isTokn ? propertyWxToken : token}`;
  } else {
    headers.Authorization = token;
    headers.market_id = userInfo.marketId;
    headers.merchants_id = userInfo.merchantsId;
  }
  let url = '';
  if (RouteUrl.indexOf(path) == -1) {
    url = type.isURl ? process.env.VUE_APP_GATEWAY_URL + path : process.env.VUE_APP_URL + path;
  } else {
    url = process.env.VUE_APP_PATH_URL + path;
  }
  const result = await request(
    {
      method: 'GET',
      url,
      headers,
      params: data
    },
    type
  );
  return result;
};

export const HttpPost = async (path = '', data = {}, type = {}) => {
  let token = window.localStorage.getItem('propertyToken') || '';
  let propertyWxToken = window.localStorage.getItem('propertyWxToken') || '';
  let userInfo = window.localStorage.getItem('propertyUserInfo')
    ? JSON.parse(window.localStorage.getItem('propertyUserInfo'))
    : {};
  let headers = {
    'Content-Type': 'application/json',
    loginType: 4
  };
  if (VerifyTokenUrl.indexOf(path) != -1) {
    headers.Authorization = `Bearer ${type.isTokn ? propertyWxToken : token}`;
  } else {
    headers.Authorization = token;
    headers.market_id = userInfo.marketId;
    headers.merchants_id = userInfo.merchantsId;
  }
  let url = '';
  if (RouteUrl.indexOf(path) == -1) {
    url = type.isURl ? process.env.VUE_APP_GATEWAY_URL + path : process.env.VUE_APP_URL + path;
  } else {
    url = process.env.VUE_APP_PATH_URL + path;
  }
  const result = await request(
    {
      method: 'POST',
      url,
      headers,
      data
    },
    type
  );
  return result;
};
