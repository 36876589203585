import { createApp } from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import App from './App';
import 'amfe-flexible/index';
import '@/assets/css/base.scss';
import '@/assets/css/vant_custom.scss';
import router from './router';
import store from './store';
import 'default-passive-events';
import * as require from './plugin/request';
import * as common from './plugin/public';
import debounce from '@/directive/debounce';
import { preview } from '@/directive/preView';
import tokenCountDown from '@/plugin/tokenCountDown';
import unit from '@/unit/plugin';
import './permission';

const app = createApp(App);
app.config.globalProperties.$require = require;
app.config.globalProperties.$common = common;
app.provide('$require', require);
router.beforeEach((to, form, next) => {
  tokenCountDown(require);
  next();
});
app.use(unit);
app.use(debounce);
app.use(preview);
app.use(Vant);
app.use(store).use(router).mount('#app');
