/**
 * [防抖]
 * @param  {[type]} Vue  [description]
 * @return {[type]}     [description]
 */
export default (Vue) => {
  Vue.directive('debounce', {
    mounted(el, binding) {
      let timer = {};
      el.addEventListener('click', () => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          binding.value();
        }, 1000);
      });
    }
  });
};
