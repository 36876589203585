const notice = [
  {
    path: '/notice/index',
    name: 'notice',
    meta: {
      title: '消息通知'
    },
    component: () => import('../views/notice/index')
  }
];

export default notice;
