<template>
  <div class="app">
    <router-view v-slot="{ Component }">
      <keep-alive include="repairerecord">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>
<script setup>
import { provide } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

const route = new useRoute();
const router = new useRouter();
const store = useStore();
provide('ctx', { router, route, store });
</script>
<style lang="scss" scoped></style>
