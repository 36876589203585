// 获取年月
export const getMonth = (time) => {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  return `${year}-${month}`;
};
//格式化时间
export const getDate = (time, format = 'YYYY-MM-DD') => {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  if (format == 'YYYY-MM-DD') {
    return `${year}-${month}-${day}`;
  }
};
/**
 * [callPhone 拨打电话]
 * @param  {[String]} phone  [手机号]
 */
export const callPhone = (phone) => {
  window.location.href = `tel:// ${phone}`;
};

/**
 * [savePicture base64图片下载到本地]
 * @param  {[type]} base64  [base64]
 * @return {[type]}        [description]
 */
export const savePicture = (base64) => {
  let arr = base64.split(',');
  let bytes = atob(arr[1]);
  let ab = new ArrayBuffer(bytes.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  let blob = new Blob([ab], { type: 'application/octet-stream' });
  let url = URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = `${new Date().valueOf()}.png`;
  let e = document.createEvent('MouseEvents');
  e.initMouseEvent(
    'click',
    true,
    false,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );
  a.dispatchEvent(e);
  URL.revokeObjectURL(url);
};
/**
 * [computationalAccuracy 计算价格或者重量，小数后面第三位向上取整]
 * @param  {[nubmer]} price [价格或者重量]
 * @return {[nubmer]}       [返回价格]
 */
export const computationalAccuracy = (price) => {
  let digit = 2;
  // 如果等于-1 那就是0位
  if (digit == -1) {
    digit = 0;
  }
  let monery = price.toString();
  let arr = monery.split('.');
  // 如果为整数就不管直接返回
  if (arr.length <= 1) {
    return price;
  }
  // 判断小数位数
  if (arr[1].length <= digit) {
    return parseFloat(price);
  }
  let decimals = '';
  for (let i = 0; i < arr[1].length; i++) {
    if (i == digit) {
      if (parseInt(arr[1][i]) > 0) {
        if (decimals.split('')[1] >= '9') {
          decimals = parseInt(decimals) + 1;
        } else if (decimals.split('')[1] < '9') {
          decimals = decimals.split('')[0] + (parseInt(decimals.split('')[1]) + 1);
        }
      }
      if (decimals == 100) {
        return parseFloat(arr[0]) + 1;
      }
      return parseFloat(`${arr[0]}.${decimals}`);
    }
    decimals += arr[1][i];
  }
  return parseFloat(price);
};
