const features = [
    {
        path: '/features/index',
        name: 'features',
        meta: {
            title: '更多'
        },
        component: () => import('../views/features/index')
    }
];

export default features;
