const property = [
  {
    path: '/property/index',
    name: 'property',
    meta: {
      title: '联系物业'
    },
    component: () => import('../views/property/index')
  }
];

export default property;
