import { createStore } from 'vuex';

export default createStore({
  state: {
    clickItem: {}
  },
  mutations: {
    changeClickItem(state, item) {
      state.clickItem = item;
    }
  },
  actions: {},
  modules: {}
});
