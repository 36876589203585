import { createApp, getCurrentInstance } from 'vue';
import confirm from './components/confirm.vue';
import emptyItem from './components/empty-item.vue';
import './style/style.scss';

function myconfirm(tips = '提示内容', title = '提示') {
  return new Promise((resolve, reject) => {
    let instance = createApp(confirm, {
      reject,
      resolve,
      titles: title,
      tips,
      isFade: true
    });
    let dom = document.createElement('div');
    dom.id = 'myconfirmbox';
    document.body.appendChild(dom);
    instance.mount('#myconfirmbox');
  });
}

const showToast = (value = '') => {
  let isExit = document.getElementById('mytoast-dom');
  if (isExit) return;
  let dom = document.createElement('div');
  dom.id = 'mytoast';
  dom.innerHTML = value;
  document.body.appendChild(dom);
  setTimeout(() => {
    document.body.removeChild(dom);
  }, 1000);
};
let pluginObj = {
  myconfirm,
  showToast
};
export default {
  install(app) {
    app.component('rConfirm', confirm);
    app.component('emptyItem', emptyItem);
    app.provide('plugin', pluginObj);
    app.directive('img', {
      beforeMount(el) {
        if (!el.src) return (el.src = require('./image/picfail.png'));
        el.onerror = function () {
          el.style.objectFit = 'contain';
          el.src = require('./image/picfail.png');
        };
        el.onload = function () {};
      }
    });
    app.directive('avatar', {
      mounted(el) {
        el.onerror = function () {
          el.style.objectFit = 'contain';
          el.src = require('./image/avatar.png');
        };
        el.onload = function () {};
      },
      updated(el) {
        el.onerror = function () {
          el.style.objectFit = 'contain';
          el.src = require('./image/avatar.png');
        };
        el.onload = function () {};
      }
    });
    app.directive('num', {
      created(el, bind) {
        let minValue = bind.value; //最小值
        let inputEl = el.querySelector('input') || el;
        inputEl.addEventListener('change', function () {
          let value = this.value;
          if (!value) return;
          let mathcValue = value.match(/^\d+(\.?\d{1,2}|\d*)/g);
          let setValue = minValue || (mathcValue ? mathcValue[0] : 0);
          this.value = setValue;
          this.dispatchEvent(new Event('input'));
          this.dispatchEvent(new Event('blur'));
        });
      }
    });
  }
};
