const account = [
  {
    path: '/account/index',
    name: 'account',
    meta: {
      title: '账号安全',
    },
    component: () => import('../views/account/index'),
  },
  {
    path: '/account/othFeePage',
    name: 'othFeePage',
    meta: {
      title: '其它费用',
    },
    component: () => import('../views/account/othFeePpage'),
  },
  {
    path: '/account/contract',
    name: 'contract',
    meta: {
      title: '合同列表',
    },
    component: () => import('../views/account/contract'),
  },
  {
    path: '/account/contractDetail',
    name: 'contractDetail',
    meta: {
      title: '合同详情',
    },
    component: () => import('../views/account/contractDetail'),
  },
  {
    path: '/account/contractSign',
    name: 'contractSign',
    meta: {
      title: '合同详情',
    },
    component: () => import('../views/account/contractSign'),
  },
  {
    path: '/account/bind',
    name: 'bind',
    meta: {
      title: '绑定手机号',
    },
    component: () => import('../views/account/bind'),
  },
  {
    path: '/account/verification',
    name: 'verification',
    meta: {
      title: '验证手机号',
    },
    component: () => import('../views/account/verification'),
  },
  {
    path: '/account/changePassword',
    name: 'changePassword',
    meta: {
      title: '修改密码',
    },
    component: () => import('../views/account/changePassword'),
  },
  {
    path: '/account/balance',
    name: 'balance',
    meta: {
      title: '账户余额',
    },
    component: () => import('../views/account/balance'),
  },
  {
    path: '/account/chargeDetail',
    name: 'chargeDetail',
    meta: {
      title: '收支详情',
    },
    component: () => import('../views/account/chargeDetail'),
  },
  {
    path: '/account/earnmoney',
    name: 'earnmoney',
    meta: {
      title: '意向金转余额',
    },
    component: () => import('../views/account/earnmoney'),
  },
  {
    path: '/account/earnMoneyDetail',
    name: 'earnMoneyDetail',
    meta: {
      title: '意向金转余额',
    },
    component: () => import('../views/account/earnMoneyDetail'),
  },

  {
    path: '/account/promisemoney',
    name: 'promisemoney',
    meta: {
      title: '保证金',
    },
    component: () => import('../views/account/promisemoney'),
  },
  {
    path: '/account/promiseDeatail',
    name: 'promiseDeatail',
    meta: {
      title: '保证金详情',
    },
    component: () => import('../views/account/promiseDeatail'),
  },
  {
    path: '/account/continueSign',
    name: 'continueSign',
    meta: {
      title: '意向金转余额',
    },
    component: () => import('../views/account/continueSign'),
  }, {
    path: '/account/downloadPDF',
    name: 'downloadPDF',
    meta: {
      title: '下载pdf',
    },
    component: () => import('../views/account/downloadPDF'),
  },
];

export default account;
