const contribute = [
  {
    path: '/contribute/index',
    name: 'contribute',
    meta: {
      title: '物业费查询/缴费'
    },
    component: () => import('../views/contribute/index')
  },
  {
    path: '/contribute/dailyFee',
    name: 'dailyFee',
    meta: {
      title: '日常费用'
    },
    component: () => import('../views/contribute/dailyFee')
  },
  {
    path: '/contribute/dailyFeeDetail',
    name: 'dailyFeeDetail',
    meta: {
      title: '使用明细'
    },
    component: () => import('../views/contribute/dailyFeeDetail')
  },
  {
    path: '/contribute/othFee',
    name: 'othFee',
    meta: {
      title: '其它费用'
    },
    component: () => import('../views/contribute/othFee')
  },
  {
    path: '/contribute/depositFee',
    name: 'depositFee',
    meta: {
      title: '保证金'
    },
    component: () => import('../views/contribute/depositFee')
  },
  {
    path: '/contribute/utilities',
    name: 'utilities',
    meta: {
      title: '电费'
    },
    component: () => import('../views/contribute/utilities')
  },
  {
    path: '/contribute/waterRates',
    name: 'waterRates',
    meta: {
      title: '水费'
    },
    component: () => import('../views/contribute/waterRates')
  },
  {
    path: '/contribute/gasFee',
    name: 'gasFee',
    meta: {
      title: '燃气费'
    },
    component: () => import('../views/contribute/gasFee')
  },
  {
    path: '/contribute/rental',
    name: 'rental',
    meta: {
      title: '商铺租金'
    },
    component: () => import('../views/contribute/rental')
  },
  {
    path: '/contribute/rentFeeDetail',
    name: 'rentFeeDetail',
    meta: {
      title: '缴费计划'
    },
    component: () => import('../views/contribute/rentFeeDetail')
  },
  {
    path: '/contribute/record',
    name: 'record',
    meta: {
      title: '缴费记录'
    },
    component: () => import('../views/contribute/record')
  },
  {
    path: '/contribute/recordDetail',
    name: 'recordDetail',
    meta: {
      title: '账单详情'
    },
    component: () => import('../views/contribute/recordDetail')
  },
  {
    path: '/contribute/recordVoucher',
    name: 'recordVoucher',
    meta: {
      title: '缴费凭证'
    },
    component: () => import('../views/contribute/recordVoucher')
  },
  {
    path: '/contribute/activity',
    name: 'activity',
    meta: {
      title: '优惠活动'
    },
    component: () => import('../views/contribute/activity')
  },
  {
    path: '/contribute/prepay',
    name: 'prepay',
    meta: {
      title: '预缴租金'
    },
    component: () => import('../views/contribute/prepay')
  },
  {
    path: '/contribute/rechargeRecord',
    name: 'prepay',
    meta: {
      title: '充值记录'
    },
    component: () => import('../views/contribute/rechargeRecord')
  }
];

export default contribute;
