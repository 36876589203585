<template>
  <div class="confirm-box" v-if="show" @touchmove="touch" @click="close">
    <transition name="fade">
      <div class="r-box" v-if="boxshow" @click.stop>
        <div class="r-title">
          <span v-if="slots">{{ titles }}</span>
          <span v-else>{{ titles }}</span>
          <img src="../../assets/image/gb.png" @click="close" />
        </div>
        <div class="r-slot" v-if="slots">
          <slot></slot>
        </div>
        <div v-else class="r-tips">{{ tips }}</div>

        <div class="r-btnbox" v-if="btn">
          <div class="r-btn" @click="close">取消</div>
          <div class="r-btn r-confirm" :class="{ disabled }" @click.stop="!disabled && confirm()">确认</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import {
 defineProps, defineExpose, ref, defineEmits
} from 'vue';

const props = defineProps({
  slots: {
    type: Boolean,
    default: false
  },
  titles: {
    type: String,
    default: ''
  },
  btn: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  resolve: {
    type: Function
  },
  reject: {
    type: Function
  },
  tips: {
    type: String,
    default: ''
  },
  isFade: {
    type: Boolean,
    default: false
  },
  position: {
    type: String,
    default: 'default'
  }
});
let show = ref(false); let
boxshow = ref(false);

const emit = defineEmits(['close', 'confirm']);
if (props.isFade) {
  fade();
}
function touch(e) {
  e.stopPropagation();
  return false;
}
function close() {
  if (props.slots && typeof props.reject == Function) {
    props.reject('cancel');
  }
  // show.value = false;
   setTimeout(() => {
     show.value = false;
  }, 10);
  boxshow.value = false;
  emit('close');
  let myconfirmbox = document.getElementById('myconfirmbox');
  if (myconfirmbox) {
    document.body.removeChild(myconfirmbox);
  }
}
function confirm() {
  if (!props.slots) {
    show.value = false;
    props.resolve('confirm');
    close();
  } else {
    emit('confirm');
  }
}
function fade() {
  show.value = true;
  setTimeout(() => {
    boxshow.value = true;
  }, 10);
}
defineExpose({ fade, confirm, close });
</script>

<style lang="scss" scoped>
.confirm-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // rgba(#000000, 0.65)
  background-color: rgba(#000000, 0.65);
  z-index: 999;
  padding: 0 24px;
  box-sizing: border-box;

  .r-box {
    width: 702px;
    min-height: 454px;
    background: #ffffff;
    border-radius: 30px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    box-sizing: border-box;

    .r-title {
      width: 100%;
      height: 100px;
      position: relative;
      line-height: 47px;
      border-bottom: 1px solid #f5f5f5;
      text-align: center;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 100px;
      font-weight: bold;
      margin-bottom: 49px;

      img {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    .r-tips {
      margin: 50px 0;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      padding: 0 58px;
      box-sizing: border-box;
    }

    .r-btnbox {
      display: flex;
      align-items: center;
      margin-top: auto;
    }

    .r-btn {
      width: 250px;
      height: 96px;
      background: #ffffff;
      border: 1px solid #dedede;
      border-radius: 48px;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #8d8d8d;
      text-align: center;
      line-height: 96px;
    }

    .r-confirm {
      color: #818080;
      margin-left: 15px;
      background: #85c226;
      border-radius: 48px;
      color: #ffffff;
      margin-left: 60px;
    }

    .disabled {
      background-color: #dddbdb;
    }
  }

  .fade-enter-from,
  .fade-leave {
    transform: translate(-50%, -50%) scale(0.2);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s;
  }

  .fade-enter,
  .fade-leave-from {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
