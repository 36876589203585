//图片放大查看
function preImage(src) {
  let box = document.createElement('div');
  box.className = 'preview-box';
  let image = new Image();
  image.src = src;
  image.className = 'previewImage';
  image.onerror = function () {
    image.src = require('../assets/image/picfail.png');
  };
  /*
  滚动
  */
  let scale = 1;
  if (window.addEventListener)
    //FF,火狐浏览器会识别该方法
    window.addEventListener('DOMMouseScroll', wheel, false);
  window.onmousewheel = document.onmousewheel = wheel; //W3C
  //统一处理滚轮滚动事件
  function wheel(event) {
    var delta = 0;
    if (!event) event = window.event;
    if (event.wheelDelta) {
      //IE、chrome浏览器使用的是wheelDelta，并且值为“正负120”
      delta = event.wheelDelta / 120;
      if (window.opera) delta = -delta; //因为IE、chrome等向下滚动是负值，FF是正值，为了处理一致性，在此取反处理
    } else if (event.detail) {
      //FF浏览器使用的是detail,其值为“正负3”
      delta = -event.detail / 3;
    }
    if (delta) handle(delta);
  }
  //上下滚动时的具体处理函数
  function handle(delta) {
    if (delta < 0) {
      //向下滚动
      if (scale <= 0.5) {
        return false;
      }
      scale -= 0.2;
      image.style.transform = `translate(-50%,-50%) scale(${scale})`;
    } else {
      //向上滚动
      if (scale >= 3) {
        return false;
      }
      scale += 0.2;
      image.style.transform = `translate(-50%,-50%) scale(${scale})`;
    }
  }

  /*
  结束
  */

  setTimeout(() => {
    image.classList.add('fade');
  }, 10);
  box.appendChild(image);
  document.body.appendChild(box);
  box.onclick = () => {
    document.body.removeChild(box);
  };
}

const preview = (Vue) => {
  Vue.directive('preview', {
    mounted(el, { value }) {
      let src = value ? value : el.src; //，可以传值  v-preview = 'src'
      el.style.cursor = 'pointer';
      el.onclick = () => {
        preImage(src);
      };
    },
    updated(el, { value }) {
      let src = value ? value : el.src;
      let elImg = el.querySelector('img');
      if (!src && elImg) {
        src = elImg.src;
      }
      el.onclick = () => {
        preImage(src);
      };
    }
  });
};

export { preview };
